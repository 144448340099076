import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { PermissionAnswer, Service as WowokService, IsValidAddress, MAX_ENDPOINT_LENGTH, Passport, PermissionIndex, PermissionIndexType,
    Permission, IsValidPercent, Protocol, Arbitration} from 'wowok';
import { SettingTitle, SettingTips, SettingLaunch, ADDRESS_VALIDATOR, SettingAddressChips, SettingInputText,
  SettingGuardPercent, ENDPOINT_VALIDATOR, SettingGuardRatio
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString, object_type, store_object_type, load_object_type } from '../../../util';
import { make_permIconProp } from '../PermCheck';

interface ArbitrationInfo {
    object: string;
    type: string;
}
interface CustomTradingData {
    withdraw: SettingGuardRatio[];
    refund: SettingGuardRatio[];
    endpoint: string;
    endpoint_err: boolean;
    machine: string;
    machine_err: boolean;
    repository: string[];
    arbitration: ArbitrationInfo[];
}

export default function CustomTrading (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokService.parseObjectType(props?.type);
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const withdraw:SettingGuardRatio[] = props?.contents?.fields?.withdraw_guard?.fields?.contents?.map((v:any) => {
        return {guard:v.fields.key, ratio:v.fields.value} as SettingGuardRatio;
    });
    const refund:SettingGuardRatio[] = props?.contents?.fields?.refund_guard?.fields?.contents?.map((v:any) => {
        return {guard:v.fields.key, ratio:v.fields.value} as SettingGuardRatio;
    });

    const [data, setData] = useState<CustomTradingData>({
        withdraw: withdraw ? withdraw : [],
        refund: refund ? refund : [],
        endpoint: props?.contents?.fields?.endpoint ?? '', // 非必需值，允许为空，需要增加错误类型来判断
        endpoint_err: false,
        machine: props?.contents?.fields?.machine ?? '', 
        machine_err: false,
        repository: props?.contents?.fields?.repositories?.length > 0 ?  props?.contents?.fields?.repositories : [],
        arbitration: props?.contents?.fields?.arbitrations?.length > 0 ?  props?.contents?.fields?.arbitrations.map((v:string)=>{
            return {object:v, type:''}}) : []
    });

    const launch_guard = async(op:'add withdraw guard' | 'remove withdraw guard' | 'add refund guard' | 'remove refund guard', param?: any) => {
        if (props!.contents!.fields!.bPublished) {
            enqueueSnackbar("Withdraw & Refund Guards immutable while service 'Allow ordering'", { variant: "error" });
            return;
        }

        if (op === 'add refund guard') {
            if (!IsValidAddress((param as SettingGuardRatio).guard) || !IsValidPercent((param as SettingGuardRatio).ratio)) {
                enqueueSnackbar('Refund guard invalid', { variant: "error" });
                return ;
            }
        }
        if (op === 'add withdraw guard') {
            if (!IsValidAddress((param as SettingGuardRatio).guard) || !IsValidPercent((param as SettingGuardRatio).ratio)) {
                enqueueSnackbar('Withdraw guard invalid', { variant: "error" });
                return ;
            }
        }
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }
        
        if (!answer) {
            enqueueSnackbar('Permission error', { variant: "error" });
            return 
        } 
    
          const txb = new TransactionBlock(); // new session
          const obj = WowokService.From(txb, type, permission, id);
          const pid = generateRandomString(8);
          const perm = (op === 'add withdraw guard' || op === 'remove withdraw guard') ? PermissionIndex.service_withdraw_guards : PermissionIndex.service_refund_guards;
          
          props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[perm], handler:
            (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
              try {
                if (id === pid) {
                  if (index.includes(PermissionIndex.service_withdraw_guards)) {
                        if (op === 'add withdraw guard') {
                            obj.add_withdraw_guards([{guard:(param as SettingGuardRatio).guard, percent:parseInt((param as SettingGuardRatio).ratio)}], passport?.get_object());
                        } else if (op === 'remove withdraw guard') {
                            obj.remove_withdraw_guards([param as string], undefined, passport?.get_object());
                        }
                  };  
                  if (index.includes(PermissionIndex.service_refund_guards)) {
                    if (op === 'add refund guard') {
                        obj.add_refund_guards([{guard:(param as SettingGuardRatio).guard, percent:parseInt((param as SettingGuardRatio).ratio)}], passport?.get_object());
                    } else if (op === 'remove refund guard') {
                        obj.remove_refund_guards([param as string], undefined, passport?.get_object());
                    }
                  };   
                  passport?.destroy(); // destroy passport
                  props.exe(pid, txb);
                }
              } catch (e) {
                console.log(e)
                enqueueSnackbar( 'Launch Failed', { variant: "error" });
              }
            }
          });
    }

    const launch = async() => {
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }

        if (data.endpoint_err) {
            enqueueSnackbar('Endpoint invalid', { variant: "error" });
            document.getElementById('endpoint')?.focus();
            return
        }

        if (data.machine_err) {
            enqueueSnackbar('Machine invalid', { variant: "error" });
            document.getElementById('machine')?.focus();
            return
        }

        let err = '';
        data.repository.forEach((v) => {
            if (!IsValidAddress(v)) {
                err = 'Repository invalid';
            }
        })
        if (err) {
            enqueueSnackbar(err, { variant: "error" });
            document.getElementById('repository')?.focus();
            return
        }
        const txb = new TransactionBlock(); // new session
        const obj = WowokService.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        const perms:PermissionIndexType[] = []; 
        
        if (data.machine !== props?.contents?.fields?.machine && (data.machine || props?.contents?.fields?.machine)) {
            if (props!.contents!.fields!.bPublished) {
                enqueueSnackbar("Machine immutable while service 'Allow ordering'", { variant: "error" });
                return;
            }
            perms.push(PermissionIndex.service_machine);
        }

        if (data.endpoint !== props?.contents?.fields?.endpoint && (data.endpoint || props?.contents?.fields?.endpoint)) {
            perms.push(PermissionIndex.service_endpoint);
        }
  
        const rep_add = data.repository.filter((v) =>props?.contents?.fields?.repositories?.find((i:string)=>i === v) === undefined);
        const rep_rem = props?.contents?.fields?.repositories?.filter((v:string) =>data.repository.find((i:string)=>i === v) === undefined);
        if (rep_add.length > 0 || rep_rem.length > 0) {
            perms.push(PermissionIndex.service_repository);
        }

        const arb_add = data.arbitration.filter((v) =>props?.contents?.fields?.arbitrations?.find((i:string)=>i === v.object) === undefined);
        const arb_rem = props?.contents?.fields?.arbitrations?.filter((v:string) =>data.arbitration.find((i)=>i.object === v) === undefined);
        if (arb_add.length > 0 || arb_rem.length > 0) {
            perms.push(PermissionIndex.service_arbitration);
        }

        props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
          (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
                //console.log(index)
              if (id === pid) {
                if (index.includes(PermissionIndex.service_machine)) {
                    obj.set_machine(data.machine, passport?.get_object());
                };  
                if (index.includes(PermissionIndex.service_repository)) {
                    if (rep_add.length > 0) {
                        rep_add.forEach((v) => obj.add_repository(v, passport?.get_object()));
                    }
                    if (rep_rem.length > 0) {
                        obj.remove_repository(rep_rem, undefined, passport?.get_object());
                    }
                };   
                if (index.includes(PermissionIndex.service_arbitration)) {
                    //console.log(arb_add)
                    if (arb_add.length > 0) {
                        arb_add.forEach((v) => obj.add_arbitration(v.object, v.type, passport?.get_object()));
                    }
                    if (arb_rem.length > 0) {
                        obj.remove_arbitration(arb_rem, undefined, passport?.get_object());
                    }
                };   
                if (index.includes(PermissionIndex.service_endpoint)) {
                    obj.set_endpoint(data.endpoint, passport?.get_object());
                }
                passport?.destroy(); // destroy passport
                props.exe(pid, txb);
              }
            } catch (e) {
              console.log(e)
              enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }
          }
        });
    }

    return ( 
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTips text='If there are no relevant guard, you can&nbsp;' command=' Build New Guard' to='/guard'/>
            <SettingGuardPercent title=' Withdraw Guard ' perm={make_permIconProp(answer, PermissionIndex.service_withdraw_guards)}
                tips='Set withdrawal guards, the payee can only withdraw funds when the guard conditions are met. Support multiple guards. '
                initValue={data.withdraw} max={16} noPerm={Permission.HasPermission(answer, PermissionIndex.service_withdraw_guards, true)?.has === false} 
                event={(type, value, id) => {
                    if (type === 'value') {
                        launch_guard('add withdraw guard', value as SettingGuardRatio);
                    } else if (type === 'cancel') {
                        launch_guard('remove withdraw guard', value);
                    }
                    //data.withdraw = type==='value'?value:[];
                    //setData({...data});
                }}/>
            <SettingGuardPercent title=' Refund Guard ' perm={make_permIconProp(answer, PermissionIndex.service_refund_guards)}
                tips='Set refund guards, the payer can only refund funds when the guard conditions are met. Support multiple guards. '
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_refund_guards, true)?.has === false}
                initValue={data.refund} max={16} event={(type, value, id) => {
                    if (type === 'value') {
                        launch_guard('add refund guard', value as SettingGuardRatio);
                    } else if (type === 'cancel') {
                        launch_guard('remove refund guard', value);
                    }
                    //data.refund = type==='value'?value:[];
                    //setData({...data});
                }}/>
            <SettingTitle title='Machine' tips='Machine object that displays the service process.'
                perm={make_permIconProp(answer, PermissionIndex.service_machine)}/>
            <SettingInputText placeholder='Enter the Machine address for the service process'  maxlength={66}
                validator={ADDRESS_VALIDATOR} value={data.machine}
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_machine, true)?.has === false}
                id='machine' event={(type, value, id) => {
                    data.machine = value; data.machine_err = type==='value'?false:true;
                    setData({...data});
                }}/>

            <SettingTitle title='Repository' tips='Repository for storing and sharing collaborative data. Support multiple repositories.'
                perm={make_permIconProp(answer, PermissionIndex.service_repository)}/>
            <SettingAddressChips placeholder='Input Repository address and press Enter' maxTags={WowokService.MAX_REPOSITORY_COUNT} maxTags_err='Max Repository count'
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_repository, true)?.has === false}
                initValue={data.repository} id='repository' event={(type, value, id) => {
                    data.repository = type==='value'?value:'';
                    setData({...data});
                }}/>
            <SettingTitle title='Arbitration' tips='Uneditable while Ordering. Arbitration Consensus promises that users can apply for arbitration of orders at any time, and initiate refund based on the arbitration results.'
                perm={make_permIconProp(answer, PermissionIndex.service_arbitration)}/>
            <SettingAddressChips placeholder='Input Arbitration address and press Enter' maxTags={WowokService.MAX_ARBITRATION_COUNT} maxTags_err='Max Arbitration count'
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_arbitration, true)?.has === false} type='Arbitration' readonly={props?.contents?.fields?.bPublished === true}
                initValue={data.arbitration.map(v=>v.object)} id='arbitration' event={(type, value, id) => {
                    const arbs: ArbitrationInfo[] = [];
                    value.forEach((v:string) => {
                        const t = Arbitration.parseObjectType(object_type(v));
                        if (t) {
                            arbs.push({object:v, type:t})
                        }
                    })
                    data.arbitration = [...arbs];
                    setData({...data});
                }} validator={{validator: async (v) => {
                    if (IsValidAddress(v)) {
                        const res = await load_object_type(v, true);
                        const t = Arbitration.parseObjectType(res);
                        if (t) {
                            return true;
                        }                         
                    }
                    return false
                }, err:'Please input Arbitration Object address'}}/> 
            <SettingTitle title='Service Endpoint' tips='The detailed description page of the service, customizable interactive interface.'
                perm={make_permIconProp(answer, PermissionIndex.service_endpoint)}/>
            <SettingInputText placeholder='Enter endpoint about service detail'  maxlength={MAX_ENDPOINT_LENGTH}
                validator={ENDPOINT_VALIDATOR}  value={data.endpoint} multiline maxRows={4}
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_endpoint, true)?.has === false}
                id='endpoint' event={(type, value, id) => {
                    data.endpoint = value; data.endpoint_err = type==='value'?false:true;
                    setData({...data});
                }}/>
            <SettingLaunch text='Launch' id='customtrading' event={(t) => {
                if (t === 'click') {
                    //console.log(data)
                    launch();
                }
            }}/>
        </Box>
    );  
  }