import React, { useEffect, useState } from 'react';
import '../../css/Common.css';
import { Box, Modal, IconButton, Tab, Tabs, Paper, Stack, Tooltip, Typography, Button, } from '@mui/material';
import Countdown, {CountdownTimeDelta} from 'react-countdown';
import { ParseType, Demand as WowokDemand, Protocol, Service, CoinTypeInfo, ResolveBalance, IsValidAddress, Passport as WowokPassport } from 'wowok';
import Avatar from '@mui/material/Avatar';
import { green, grey, red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { Pannel, a11yProps,  } from '../util/Common';
import { PaginatedEvents, SuiEvent } from '@mysten/sui/client';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CloseIcon from '@mui/icons-material/Close';
import { SettingTitle, SettingInputText, ADDRESS_VALIDATOR, SettingShowValue, SettingLaunch} from '../launch/Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Address } from '../util/Address';
import { useLocation } from 'react-router-dom';
import Events from './Present';
import { generateRandomString } from '../../util';
import { PermGuardIcon } from '../util/Icons';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';

function Coin(props:any) {
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().GetCoinTypeInfo(props.coin, (info) => {
        setCoinType(info);
      }))

    let balance  = BigInt(0);
    try {
        props.contents.fields.bounty?.forEach((e:any) => {
            balance = balance + BigInt(e.fields.balance);
        });          
    } catch (e) {
        console.log(e)
    }

    let tips = '';
    if (coinType !== 'loading') {
       tips = ResolveBalance(balance.toString(), coinType.decimals);
    }

    return (<Box display="flex" margin='1em'  width="100%" height="10em" bgcolor="primary.main" flexDirection="column" >
            <Box display="flex" width="100%" justifyContent="center" alignItems="center" flex="1 1 auto">
                <Typography  variant="h3" gutterBottom fontWeight="800">
                    {tips}
                </Typography>
                <Tooltip title={props.coin} arrow >
                    <Typography  variant="h5" gutterBottom fontWeight="200" paddingLeft="0.4em" paddingTop="0.2em">
                        {coinType !== 'loading' && coinType.symbol}
                    </Typography>
                </Tooltip>
            </Box>
        </Box>)
}


function Earnest(props:any) {
    const type = WowokDemand.parseObjectType(props?.type)
    const parse = ParseType(type);
    
    if (parse.isCoin) {
        return <Coin {...props} coin={parse.coin} />;
    } else {
        let i = type.indexOf('<');
        let token = type;
        if (i > 0)  {
            token = type.slice(0, i);
        }
        i = token.lastIndexOf('::');
        if (i) { /// object
            return (<Box display="flex" margin='1em'  width="100%" height="10em" bgcolor="primary.main" flexDirection="column" >
                <Box display="flex" width="100%" justifyContent="center" alignItems="center" flex="1 1 auto">
                    <Tooltip title={type} arrow >
                        <Typography  variant="h5" gutterBottom fontWeight="800" paddingLeft="0.4em" paddingTop="0.2em">
                            {props.contents.fields.bounty.length ? props.contents.fields.bounty.length : ""} {token.slice(i+2)}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>)
        }
        return null;
    }
}

export const Clock = (props:any) => {
    let color = props.props.contents.fields.yes ? grey[500] : green[500];
    let days = props.props.contents.fields.yes ? 0 : props.days;
    let hours = props.props.contents.fields.yes ? 0 : props.hours;
    let minutes = props.props.contents.fields.yes ? 0 : props.minutes;
    let seconds = props.props.contents.fields.yes ? 0 : props.seconds;

    let ret = days < 99 ?  (
    <Stack direction="row" spacing={2} alignItems="center">
        <Avatar sx={{ bgcolor: color }} variant="rounded">
            {days.toString().padStart(2, '0')}
        </Avatar> <span>:</span>
        <Avatar sx={{ bgcolor: color }} variant="rounded">
            {hours.toString().padStart(2, '0')}
        </Avatar> <span>:</span>
        <Avatar sx={{ bgcolor: color }} variant="rounded">
            {minutes.toString().padStart(2, '0')}
        </Avatar><span>:</span>
        <Avatar sx={{ bgcolor: color }} variant="rounded">
            {seconds.toString().padStart(2, '0')}
        </Avatar>
    </Stack>) : (
        <Stack sx={{ color: color }}> 
            <Typography  variant="h5" gutterBottom fontWeight="800">
            More than 99 days left
            </Typography>
        </Stack>
    )
    return ret;
};

interface PresentService {
    service: string;
    tips: string;
}

function Present(props:any) {
    console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const guard = props?.contents?.fields?.guard;
    const service_identifier = props?.contents?.fields?.service_identifier ?? '';
    const type = WowokDemand.parseObjectType(props?.type);
    const permission = props?.contents?.fields?.permission;
    const events = props.events as PaginatedEvents | null;
    const [bComplete, setComplete] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<PresentService>({service:'', tips:''});

    let time = props.contents.fields.time_expire ??  999999999999999;
    const theme = useTheme(); 
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();

    const OnCountdownComplete = (timeDelta: CountdownTimeDelta, completedOnStart: boolean) => {
        setComplete(true)
    }

    const launch = async(service_type:string, txb:TransactionBlock, passport?:WowokPassport) => {
        const obj = WowokDemand.From(txb, type, permission, id);

        try {
            if (guard && service_identifier) {
                obj.present(service_identifier as number, service_type, data.tips, passport?.get_object());
            } else {
                obj.present(data.service, service_type, data.tips, passport?.get_object());
            }
            passport?.destroy(); // notice, destroy or freeze object
            props.exe(generateRandomString(8), txb)
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }

    const yes = events?.data?.find((v) => (v.parsedJson as any).service === props.contents.fields.yes)?.sender;

    return (
    <Box display="flex" width="100%" height="10em" margin="1em" flexDirection="column" >
        <Box display="flex" width="100%" justifyContent="center" alignItems="center" flex="1 1 auto">
            <Countdown date={parseInt(time)} {...props} renderer={Clock} onComplete={OnCountdownComplete}/>
        </Box>
        { !props.contents.fields.yes && !bComplete && 
            <Button variant='outlined'  className='cmdButton'  style={{marginTop:'1em'}}
                disabled={props.contents.fields.yes || bComplete} onClick={() => {
                    setOpen(true); 
                }}>{<PermGuardIcon guard={guard ? [guard] : []} text='Present'/>}</Button>            
        }
        {
            props.contents.fields.yes && yes &&
            <Box  sx={{m:'0 2em', display:'flex', justifyContent:'center', borderBottom:'1px solid', borderColor:theme.palette.primary.main}} style={{color:theme.palette.primary.main, textTransform:'none'}} >
                <MilitaryTechIcon sx={{width:'.8em', height:'.8em', ml:'.2em', color:theme.palette.primary.main}} />
              <Address address={yes} />  &nbsp; GOT the bounty
            </Box>
        }
        <Modal open={open} disableRestoreFocus >
            <Paper sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'42em', p:'1em 2em 2em 2em',
                boxShadow:'none', border:'2px solid', borderColor:theme.palette.primary.main,
            }}>
                <IconButton sx={{float:'right'}} onMouseDown={()=>{setOpen(false)}}>
                    <CloseIcon />
                </IconButton>    
                <Box width='100%' sx={{textAlign:'center'}}>
                    <Typography variant="h5">Present Service</Typography>
                </Box>            

                <div style={{marginTop:'2em'}}>
                    {(!guard || !service_identifier) && <><SettingTitle required title='Service'/>
                     <SettingInputText autoFocus placeholder='Enter service address' maxlength={66} validator={ADDRESS_VALIDATOR}
                        id='presenter-service' value={data.service} event={(type, value, id) => {
                            data.service = value ;
                            setData({...data})
                        }}/>
                    </>}
                    { guard && service_identifier && <div>
                        <SettingShowValue title={'Service from Guard Witness Identifier'} value={<span style={{display:'flex', alignItems:'center'}}>
                            <Address address={guard} showType='Guard'/>
                            <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{(service_identifier  ?? '')}</span>
                            </span>} tips={'The service address comes from the witness address that you will submit to Guard later on launching.'}/>
                    </div>}
                    <SettingTitle title='Recommendation'/>
                    <SettingInputText placeholder='Tell the demander why you recommend it :)' maxlength={512} multiline maxRows={5}
                        id='presenter-tips' value={data.tips} event={(type, value, id) => {
                            data.tips = value ;
                            setData({...data})                                
                        }}/>

                    <button className='cmdButton' style={{marginTop:'1.5em'}} onClick={async ()=>{
                        var service_type = '';

                        if ((!guard || !service_identifier)) {
                            if (!IsValidAddress(data.service)) {
                                enqueueSnackbar('Service address invalid', { variant: "error" });
                                document.getElementById('presenter-service')?.focus();
                                return;
                            }
                            service_type = Service.parseObjectType((await Protocol.Client().getObject({id:data.service, options:{showType:true}})).data?.type);
                            if (!service_type) {
                                enqueueSnackbar('Service address invalid', { variant: "error" });
                                document.getElementById('presenter-service')?.focus();
                                return;
                            }
                        }

                        if (!wallet.connected) {
                            enqueueSnackbar('Please login wallet', { variant: "error" });
                            document.getElementById('header-wallet-cmd')?.click();
                            return ;
                        }

                        if (guard) {
                            // new session first, that passort could use the new session
                            props.GuardCheck({id:generateRandomString(8), txb:new TransactionBlock(), guards:[guard], 
                                handler:(id:string, txb:TransactionBlock, passport?:WowokPassport)=>{
                                    launch(service_type, txb, passport);
                            }})
                        } else {
                            launch(service_type, new TransactionBlock());
                        }
                        setOpen(false);
                    }}>Launch</button>                    
                </div>
            </Paper>
        </Modal>
    </Box>)
}

function Info(props:any) {
    return (
    <Paper>
        <Box display="flex">
            <Box display="flex" width="62%" >
                <Earnest {...props}/>
            </Box>
            <Box display="flex" width="38%">
                <Present {...props} />
            </Box>
        </Box>
    </Paper>
    )
}

export default function Demand(props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const location = useLocation();
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 1 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        window.location.hash = '#' + newValue;
        setValue(newValue);
      };

    const [events, setEvents] = useState<PaginatedEvents | null>(null);
    const QueryEvents = (count:number, result:SuiEvent[], type:string, cursor?:any) => {
        Protocol.Client().queryEvents({query:{MoveEventType:type}, cursor:cursor}).then((res) => {
            count += 1;
            res.data.filter((v) => (v.parsedJson as any)?.object === id).forEach((v) => {
                let f = result.find((i) => (i.parsedJson as any).service === (v.parsedJson as any).service);
                if (f) {
                    f = {...v};
                } else {
                    result.push(v);
                }
            });

            res.data = [...result];
            setEvents(res);

            if (res.hasNextPage && count <= 80) {
                QueryEvents(count, result, type, res.nextCursor);
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    var result:SuiEvent[] = [];
    var count = 0;
    useEffect(() =>  {
        /*  Unsupported("This query type is not supported by the full node.")
        Protocol.Client().queryEvents({query:{MoveEventField:{path:'/demand', value:id}}}).then((res) => {
            setEvents(res);
            console.log(res);
        }).catch((e) => {
            console.log(e)
        });*/
        const t = Protocol.Instance().Package('wowok') + '::demand::PresentEvent';
        QueryEvents(count, result, t);

    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Info {...props} events={events}></Info>
            <Paper sx={{ width: '100%', mt:'1em', pt:'.4em'}} >
                <Tabs value={value} onChange={handleChange} aria-label="reward" sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
                <Tab label={'Present'} {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}}/>
                <Tab label={'Promise'} {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}}/>
                </Tabs>
                <Pannel value={value} index={0}>
                    <Events {...props} events={events} />
                </Pannel>
                <Pannel value={value} index={1}>
                    <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em', mt:'2em'}}>
                        <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
                            value={<Address address={props?.contents?.fields?.permission} showType={true}/>} />
                        { props?.contents?.fields?.guard && 
                            <SettingShowValue title='Present Guard' tips='Demand can be claimed only when the guard conditions are met.'
                                value={<Address address={props?.contents?.fields?.guard} showType={true}/>} />
                        }
                        { !props?.contents?.fields?.guard && 
                            <SettingShowValue title='Present Guard' tips='Demand can be claimed only when the guard conditions are met.'
                                value='Not set yet' />
                        }
                        <SettingLaunch text='More...' event={(type, value, id) => {
                            if (type === 'click') {
                                window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
                            }
                        }}/>
                    </Box>
                </Pannel>
            </Paper>
        </Box>
    )
}

