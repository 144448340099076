
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Chip} from '@mui/material';
import { SettingShowValue, SettingLaunch, SettingTitle, SettingAddressChips, SettingGuardRatio } from '../launch/Settings';
import { Address } from '../util/Address';  
import { NotSetYet } from '../util/Common';
import Status from '../launch/service/Status';
import { grey } from '@mui/material/colors';

function Reqired (props: any) {
    return (<div style={{display:'flex', alignItems:'center'}}>
        {props.req.map((v:string) => {
            return <Chip color="primary" key={v} label={v} sx={{mr:'.4em'}}/>
        })}
    </div>)
}

export default function Details (props:any) {
    let req:string[] = [];
    if (props?.contents?.fields?.customer_required?.fields?.customer_required_info) {
        req = props?.contents?.fields?.customer_required?.fields?.customer_required_info?.map((v:number[]) => {
            return v.map((i) => String.fromCharCode(i)).join('');
        })
    }
    const withdraw:SettingGuardRatio[] = props?.contents?.fields?.withdraw_guard?.fields?.contents?.map((v:any) => {
        return {guard:v.fields.key, ratio:v.fields.value} as SettingGuardRatio;
    });
    const refund:SettingGuardRatio[] = props?.contents?.fields?.refund_guard?.fields?.contents?.map((v:any) => {
        return {guard:v.fields.key, ratio:v.fields.value} as SettingGuardRatio;
    });
    return (
        <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em',}}>
            <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
                value={<Address address={props?.contents?.fields?.permission ?? ""} showType='Permission' maxWidth='20em'/> }/>
            <SettingShowValue title='Machine' tips="Progress consensus between Server and buyer."
                value={props?.contents?.fields?.machine ? <Address address={props?.contents?.fields?.machine} showType='Machine'  maxWidth='20em'/>:<NotSetYet/>} />
            <SettingShowValue title='Buyer Guard' tips="Only buyers who meet the conditions can place orders for the service. "
                value={props?.contents?.fields?.buy_guard ? <Address address={props?.contents?.fields?.buy_guard} showType='Guard'  maxWidth='20em'/>:<NotSetYet/>} />
            <SettingShowValue title='Required information' tips="Buyer information required, such as contact information and shipping address."
                value={req.length > 0 ? <Reqired req={req}/>:<NotSetYet/>} />
            <SettingShowValue title='Service Endpoint' tips='To provide unified and customized operation pages, improving operational efficiency and experience.'
                value={props?.contents?.fields?.endpoint ? <span className='cmdText'  onClick={()=> {
                    window.open(props?.contents?.fields?.endpoint, '_blank')?.focus()
                }}>{props?.contents?.fields?.endpoint}</span> : <NotSetYet/>}/>
            <SettingShowValue title='Payee Treasury' tips='The payee Treasury is used to receive withdrawals for all orders.'
                value={<Address address={props?.contents?.fields?.payee ?? ""} showType={'Treasury'}  maxWidth='20em'/> }/>
            <SettingShowValue title='Treasury for External Withdrawals' tips='ONLY persons other than the Permission may withdraw funds based on meeting the immutable Guard condition.'
                value={props?.contents?.fields?.extern_withdraw_treasuries.length === 0 ? <NotSetYet/>:undefined} />
            { props?.contents?.fields?.extern_withdraw_treasuries?.length > 0 && <SettingAddressChips maxTags={100} 
                readonly={true} initValue={props?.contents?.fields?.extern_withdraw_treasuries} />
            }
            <SettingShowValue title='Arbitration' tips='Arbitration Consensus promises that users can apply for arbitration of orders at any time, and initiate refund based on the arbitration results.'
                value={props?.contents?.fields?.arbitrations?.length === 0 ? <NotSetYet/>:undefined} />
            { props?.contents?.fields?.arbitrations?.length > 0 && <SettingAddressChips maxTags={100} type='Arbitration'
                readonly={true} initValue={props?.contents?.fields?.arbitrations} />
            }
            <SettingShowValue title='Withdraw Guard' tips='Service can withdraw funds from an order when the Guard condition is met.'
                value={(withdraw.length === 0) ? <NotSetYet/>:undefined} />
            { withdraw.length > 0 && 
            <div style={{backgroundColor:'#e8a7d53b', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em', }}>
                {  
                    withdraw?.map((v, index:number)=> {
                        return (<div style={{display:'flex', alignItems:'center', marginBottom:'.6em', marginTop:'.6em'}}>
                            <span style={{color:grey[500], fontStyle:'italic', paddingRight:'1em'}}>{index+1}</span>
                            <Address address={v.guard} showType={'Guard'} /> 
                            <span style={{paddingLeft:'4em'}}>{v.ratio + '%'}</span>
                        </div>)
                    })
                }
            </div>}
            <SettingShowValue title='Refund Guard' tips='Payer can refund funds from an order when the Guard condition is met.'
                value={refund.length === 0 ? <NotSetYet/>:undefined} />
            { refund.length > 0 && 
            <div style={{backgroundColor:'#e8a7d53b', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em',}}>
                {  
                    refund?.map((v, index:number)=> {
                        return (<div style={{display:'flex', alignItems:'center', marginBottom:'.6em', marginTop:'.6em'}}>
                            <span style={{color:grey[500], fontStyle:'italic', paddingRight:'1em'}}>{index+1}</span>
                            <Address address={v.guard} showType={'Guard'} /> 
                            <span style={{paddingLeft:'4em'}}>{v.ratio + '%'}</span>
                        </div>)
                    })
                }
            </div>}
            {/*<div style={{marginLeft:'-2em'}}><Status {...props}/></div>*/}
            <SettingLaunch text='More...' event={(type, value, id) => {
                if (type === 'click') {
                    window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
                }
            }}/>
        </Box>
    );  
  }