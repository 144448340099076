import React, { useState } from 'react';
import '../../css/App.css';
import { Protocol, Passport, IsValidAddress,  MAX_DESCRIPTION_LENGTH, Arbitration as WowokArbitration, MAX_NAME_LENGTH,
    CoinTypeInfo, Service,} from 'wowok'
import { Box, Button, } from '@mui/material';
import { SettingTitle, SettingTips, SettingInputText } from '../launch/Settings';
import { generateRandomString, sliceAddress } from '../../util';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { useWallet } from '@suiet/wallet-kit';
import { useTheme, } from '@mui/material/styles';
import { useAccount,add_resource_launched } from '../util/Account';

class Data {
    order: string='';
    description: string='';
    proposition: string[] = ['', '', '', ''];
}

class OrderInfo {
    order: string='';
    token_type: string='';
    agents: string[]=[];
    payer: string='';
}

interface Balance {
    object: string;
    balance: bigint;
}

export default function Arb (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokArbitration.parseObjectType(props?.type);
    const permission = props?.contents?.fields?.permission;
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const account = useAccount();
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().GetCoinTypeInfo(type, (res) => {
        if (res.type === type) {
          setCoinType(coinType);      
        }
      }))
    const [data, setData] = useState<Data>(new Data());
    const [order, setOrder] = useState<OrderInfo>(new OrderInfo());

    const launch = async() => {
        var balance: Balance[] = []; var total = BigInt(0);
        const fee = props?.contents?.fields?.fee ? BigInt(props?.contents?.fields?.fee) : BigInt(0);

        if (fee > BigInt(0)) {
            try {
                const res = await Protocol.Client().getOwnedObjects({owner:wallet.address!, filter:{StructType: '0x2::coin::Coin<'+type+'>'}, options:{showContent:true, showType:true}});
                balance = res?.data?.map((i:any) => {
                    total += BigInt(i?.data?.content?.fields?.balance);
                    return {balance:BigInt(i?.data?.content?.fields?.balance), object:i?.data?.objectId}
                })
                if (total < BigInt(fee)) {
                    enqueueSnackbar("NOT enough tokens to cover the cost of Arbitration", { variant: "error" });
                    return ;
                }
            } catch(e) {
                console.log(e)
                enqueueSnackbar("Launch failed", { variant: "error" });
                return ;
            }            
        }

        const txb = new TransactionBlock();
        const obj = WowokArbitration.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        var coin: any; 

        try {
            if (fee > BigInt(0)) {
                //@ SUI 从GAS获得
                if (type === '0x2::sui::SUI' || type === '0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI') {
                    coin = txb.splitCoins(txb.gas, [fee])                    
                } else {
                const coins = []; let current = BigInt(0); 
                for (let i = 0; i < balance.length; ++ i) {
                    if (current >= fee) { 
                    break;
                    }
                    current = current + balance[i].balance;
                    coins.push(balance[i].object);
                };
                //console.log(depositData)
                if (coins.length > 1) {
                    coin = coins.pop()!;
                    txb.mergeCoins(coin, coins);
                } else if (coins.length === 1){
                    coin = txb.splitCoins(coins[0], [fee]);
                }   
                }                
            }

            props.GuardCheck({id:pid, txb:txb, guards:props?.contents?.fields?.usage_guard ? [props?.contents?.fields?.usage_guard] : [], handler:
                (id:string, txb:TransactionBlock, passport?:Passport) => {
                try {
                    if (id === pid) {
                        add_resource_launched(account, txb, [obj.dispute({order:txb.object(data.order), order_token_type:order.token_type, description:data.description, 
                            votable_proposition:data.proposition.filter((v)=>v!==''), fee: (fee > BigInt(0)) ? txb.object(coin) : undefined}, passport?.get_object())]);
                        passport?.destroy(); // destroy passport
                        props.exe(pid, txb, 'arb::Arb');
                    }
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar( 'Launch Failed', { variant: "error" });
                }
                }
            });              
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
    }
    if (props?.contents?.fields?.bPaused) {
        return <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
            <SettingTips text={'Arbitration is paused, and Arbs cannot be created.'} />
        </Box>
    } else {
        return ( 
            <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
                <SettingTips text={'Order payer or agents can apply for Arbitration, and the result of the arbitration will appear in the Order.'} />
                <SettingTips text={'Whether the Arbitration results in favor of a refund of the order depends on whether the arbitration is supported by the service commitments you made when paying the order.'} />
                <SettingTitle title='Order' required tips='The address of the order to be arbitrated.' />
                <SettingInputText validator={{validator: async (value:string) => {
                    if (IsValidAddress(value)) { //@ 用value，不用data
                        let order_token = '';
                        try {
                            const res = await Protocol.Client().getObject({id:value, options:{showType:true, showContent:true}}); 
                            order_token = Service.parseOrderObjectType(res?.data?.type);
                            if (order_token) {
                                const c: any = res?.data?.content;
                                setOrder({order:value, token_type:order_token, payer:c?.fields?.payer??"", agents:c?.fields?.agent??[]});
                                return true
                            }
                        } catch(e) {
                            console.log(e);
                        }
        
                        enqueueSnackbar(sliceAddress(value) + ' is not an Order object', { variant: "error" });
                        return false;
                    } else {
                        return false;
                    }
                }, err:''}} value={data.order} maxlength={66} placeholder='Enter Order address'
                    id='order-arb-addr' err_empty='Please Enter Order address' autoFocus event={(t, v, d) => {
                        data.order = v;
                        setData({...data});
                    }}/>
                <SettingTitle title='Description' required tips={'Event description, information and points of divergence.'}/>
                <SettingInputText placeholder='Enter the event description, information and points of divergence' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
                    id='order-arb-description' value={data.description} err_empty='Please enter the event description, information and points of divergence.' event={(t, v, d)=>{
                        data.description = v;
                        setData({...data});
                }}/>
                <SettingTitle title='Proposition for Compensation 1' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
                <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
                    id='order-arb-p1'  value={data.proposition[0]} event={(t, v, d)=>{
                        data.proposition[0] = v;
                        setData({...data});
                }}/>  
                <SettingTitle title='Proposition for Compensation 2' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
                <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
                    id='order-arb-p2' value={data.proposition[1]} event={(t, v, d)=>{
                        data.proposition[1] = v;
                        setData({...data});
                }}/>        
                        <SettingTitle title='Proposition for Compensation 3' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
                <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
                    id='order-arb-p3' value={data.proposition[2]} event={(t, v, d)=>{
                        data.proposition[2] = v;
                        setData({...data});
                }}/>        
                        <SettingTitle title='Proposition for Compensation 4' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
                <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
                    id='order-arb-p4' value={data.proposition[3]} event={(t, v, d)=>{
                        data.proposition[3] = v;
                        setData({...data});
                }}/>              
                <Button variant='outlined' className='cmdButton' sx={{mt:'1em', textTransform:'none', fontSize:'1.2em'}} 
                    onClick={async ()=> {
                            if (!wallet.connected || !wallet.address) {
                                enqueueSnackbar('Please login wallet', { variant: "error" });
                                document.getElementById('header-wallet-cmd')?.click();
                                return ;
                            }
                            if (!IsValidAddress(data.order)) {
                                enqueueSnackbar('Order invalid', { variant: "error" });
                                document.getElementById('order-arb-addr')?.click();
                                return ;
                            }
                            if (!data.description) {
                                enqueueSnackbar('Description invalid', { variant: "error" });
                                document.getElementById('order-arb-description')?.click();
                                return ;
                            }
                            if (!order || order?.order !== data.order) {
                                enqueueSnackbar('Fetch Order' + sliceAddress(data.order) + ' info failed', { variant: "error" });
                                return 
                            }
                            if (wallet.address !== order?.payer && !order?.agents.includes(wallet.address)) {
                                enqueueSnackbar('Only the Order payer or agents may apply for arbitration', { variant: "error" });
                                document.getElementById('order-arb-addr')?.click();
                                return ;
                            }
        
                            launch()
                        }}>Apply for
                    </Button>   
            </Box>
            );
    }
}