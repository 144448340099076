
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, } from '@mui/material';
import { SettingShowValue, SettingLaunch, SettingTitle, SettingAddressChips } from '../launch/Settings';
import { grey } from '@mui/material/colors';
import { Address } from '../util/Address';  
import Status from '../launch/machine/Status';
import { NotSetYet } from '../util/Common';

export default function Details (props:any) {
    return (
        <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em',}}>
            <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
                value={<Address address={props?.contents?.fields?.permission ?? ""} showType={'Permission'}  maxWidth='20em'/> }/>
            <SettingShowValue title='Endpoint' tips='To provide unified and customized operation pages, improving operational efficiency and experience.'
                value={props?.contents?.fields?.endpoint ? <span className='cmdText' onClick={()=> {
                    window.open(props?.contents?.fields?.endpoint, '_blank')?.focus()
                }}>{props?.contents?.fields?.endpoint}</span> : <span style={{color:grey[400]}}>Not set yet</span>}/>
            <SettingShowValue title={'Repository'} tips={'Storing and sharing machine data. Support multiple repositories.'} 
                value={props?.contents?.fields?.consensus_repositories?.length === 0 ? <NotSetYet/> : undefined}/>
            {props?.contents?.fields?.consensus_repositories?.length > 0 && <SettingAddressChips placeholder='' readonly={true}
             maxTags={100}  initValue={props?.contents?.fields?.consensus_repositories ?? []} />}
            <div style={{marginLeft:'-2em', marginBottom:'-2em'}}><Status {...props}/></div>
            <SettingLaunch text='More...' event={(type, value, id) => {
                if (type === 'click') {
                    window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
                }
            }}/>
        </Box>
    );  
  }