
import React, { useEffect, useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Select, MenuItem} from '@mui/material';
import { PermissionAnswer, PermissionIndex, Demand as WowokDemand, Permission as WowokPermission, Protocol, 
  PermissionIndexType, Passport as WowokPassport, Guard as WowokGuard, IsValidAddress, IsValidU8,
} from 'wowok';
import { SettingTitle,  SettingLaunch, SettingInputText, SettingTips, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString, load_object_type } from '../../../util';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
export interface GuardData {
    guard: string;
    type: string;
    service_identifiers:number[];
    current: string;
}

export default function Guard (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokDemand.parseObjectType(props?.type);
    const [data, setData] = useState<GuardData>(
        {guard:props?.contents?.fields?.guard ?? '', service_identifiers:[], type:'Guard',
          current:props?.contents?.fields?.service_identifier ?? ''});
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const answer: PermissionAnswer | undefined = props?.perms;
    const permission  = props?.contents?.fields?.permission;
    
    useEffect(() => {
      if (IsValidAddress(data.guard)) {
        WowokGuard.QueryAddressIdentifiers(data.guard, (answer) => {
          data.service_identifiers = [...answer.identifiers];
          setData({...data});
        }, wallet.address ?? '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f');        
      } else {
        data.service_identifiers = []; data.type = ''; data.current = ''
        setData({...data})
      }
    }, [data.guard])

    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTips text='If there are no relevant guard, you can&nbsp;' command=' Build New Guard' to='/guard'/>
            <SettingTitle title='Presenting Guard' tips='Service can be presented for Demand only when the guard conditions are met.'
              perm={make_permIconProp(answer, PermissionIndex.demand_guard)} required />
            <SettingInputText placeholder='Enter the Guard address'  maxlength={66} 
                noPerm={WowokPermission.HasPermission(answer, PermissionIndex.demand_guard)?.has === false}
                validator={{validator: async (value:string) => {
                  if (IsValidAddress(value)) { //@ 用value，不用data
                      try {
                          const res = await load_object_type(value, true);
                          data.type = Protocol.Instance().object_name_from_type_repr(res);
                          setData({...data})
                          if (data.type === 'Guard') {
                            return true;
                          }
                      } catch(e) {
                          console.log(e);
                      }
                  } 
                  data.type = ''; setData({...data})
                  return false;
              }, err:'Please enter a Guard address. The address starts with "0x" and 66 length'}} value={data.guard}
                id='demand-guard-addr' event={(type, value, id) => {
                    data.guard = value;  
                    setData({...data});
                }}/>   
            <SettingTitle title='Service from Guard Witness Identifier' 
              tips='The service address is the Witness address submitted by the user when verifying the Guard; It is used when Guard is used for Service data checking.'
              perm={make_permIconProp(answer, PermissionIndex.demand_guard)} />  
            <Select fullWidth displayEmpty
                disabled={WowokPermission.HasPermission(answer, PermissionIndex.demand_guard)?.has === false}
                renderValue={(value)=> {
                  if (!value) {
                    return <span>Not use Witness Identifier</span>
                  } else {
                    return <span>{'Witness Identifier: '+value}</span>
                  }
                }}
                value={(data.current ?? '').toString()} id='demand-guard-identifer' onChange={(e) => {
                  data.current = e.target.value;
                  setData({...data})
                }}> 
                <MenuItem value={''}>Not use Witness Identifier</MenuItem>
                {
                  data.service_identifiers.map((v)=>{
                    return <MenuItem value={v}>{'Witness Identifier: '+v}</MenuItem>
                  })
                }
            </Select>         
            <SettingLaunch text='Launch' event={(t) => {
                if (t === 'click') {
                  if (!wallet.connected) {
                    enqueueSnackbar('Please login wallet', { variant: "error" });
                    document.getElementById('header-wallet-cmd')?.click();
                    return 
                  }

                  if (!IsValidAddress(data.guard) || data.type !== 'Guard') {
                    enqueueSnackbar('Guard address invalid', { variant: "error" });
                    document.getElementById('demand-guard-addr')?.focus();
                    return
                  }
                  //console.log(data)
                  if (data.current && !IsValidU8(data.current)) {
                    enqueueSnackbar('Service from Guard Witness Identifier invalid', { variant: "error" });
                    document.getElementById('demand-guard-identifer')?.focus();
                    return
                  }
                  
                  const txb = new TransactionBlock();
                  const obj = WowokDemand.From(txb, type, permission, id);
                  const pid = generateRandomString(8);
                  const perms: PermissionIndexType[] = [];
                  if (!(!data.guard  && !props?.contents?.fields?.guard) || (data.guard === props?.contents?.fields?.guard)) {
                    perms.push(PermissionIndex.demand_guard);
                  };

                  props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.demand_guard], handler:
                    (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
                      try {
                        if (id === pid) {
                          if (index.includes(PermissionIndex.demand_guard)) {
                            obj.set_guard(data.guard ?? undefined, data.current ? parseInt(data.current) : undefined, passport?.get_object())
                          };  
                          passport?.destroy(); // destroy passport
                          props.exe(id, txb);            
                        }
                      } catch (e) {
                        console.log(e)
                        enqueueSnackbar( 'Launch Failed', { variant: "error" });
                      }
                    }
                  });
                }
            }}/>
        </Box>
    );  
}