

import '../../css/App.css';
import { useState } from 'react';
import { Session, Progress, Deliverable } from 'wowok'
import { Tooltip, Typography, Paper, IconButton } from '@mui/material';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Address } from "../util/Address";
import { green, grey } from '@mui/material/colors';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { OriginNode } from "../util/Common";
import DeliverableShow from './Deliverable';

export default function ProgressHistory(props:any) {
  console.log(props)
  const machine = props?.contents?.fields?.machine;
  const bComplete:boolean = !props.waiting && props.nextNodes.length === 0 && props.machineNodes !== 0;
  const [show, setShow] = useState<Deliverable|undefined>(undefined);

  let sessions : Session[] = Progress.rpc_de_history(props?.fields).sort((a, b) => {return a.id! - b!.id!});
  const len = sessions.length >= 20 ? 20 : (sessions.length <= 5 ? 5 : 10);

  return (
    <Paper className='noboxshadow' sx={{}}>
        <MaterialTable title=''
        columns={[
          { title:'SID', field:'id', cellStyle: {paddingRight: 0, paddingLeft:'4px'}, render:(data,type) => {
            return (<div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.id}</div>);
          }},
          { title: 'Node', field: 'node', cellStyle: {paddingLeft: 0},  render:(data, type) => {
            return (<div  className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'12em', }} onClick={()=>{
              window.open('/'+machine, '_blank')?.focus();
            }}>
              {data.node==='' && <OriginNode />} {data.node}
            </div>)
          }},
          { title: 'Next Node', field: 'next', render:(data, type) => {
              return (<div  className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'12em', }} onClick={()=>{
                window.open('/'+machine, '_blank')?.focus();
              }}>
                {data.next==='' && <OriginNode size='1em' text='Origin' />} {data.next}
              </div>)
          }},
          { title: 'Loading', field: 'loading', render:(data, t) => {
            const color = data.bComplete ? {color:grey[900]} : {color: grey[500]};
            return (<div style={{textAlign:'right', ...color}}>
              {data.loading}
              </div>)
          }},
          { title: 'Operation', field: 'op', cellStyle: {paddingRight: 4},  render:(data, type) => {
            return (<div >
            {data.op.length > 0 && data.op.map((v) => {
              return (<div style={{display:'flex', alignItems:'center', marginTop:'.2em', marginBottom:'.2em'}}>
                {v.accomplished && <div style={{backgroundColor:'rgba(112,182,3, 1)', borderRadius:'.6em', padding:'.2em .4em .1em .4em', 
                  marginRight:'1em', fontSize:'.8em'}}>Done</div>}
                {!v.accomplished && <div style={{backgroundColor:'rgba(95,0,170, 0.12)', borderRadius:'.6em', padding:'.2em .4em .1em .4em',
                  marginRight:'1em', fontSize:'.8em'}}>Pending</div>}
                {v.who && <div style={{display:'flex', color:grey[400]}}><Address address={v.who}/><span>:&nbsp;</span></div>}
                <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',maxWidth:'26em'}} className='cmdText' onClick={()=>{
                  window.open('/'+machine, '_blank')?.focus();
                }}>{ v.forward}</div>
                { v.accomplished  && <div style={{marginLeft:'auto', display:'flex', alignItems:'center'}}>
                    <Tooltip title={'Deliverable'}>
                      <IconButton style={{padding:'5px'}} className= "cmdText" onClick={() => {
                          setShow(v.deliverable);
                      }}><InsertChartIcon style={{width:'.82em', height:'.82em', color:'rgba(112,182,3, 1)'}}/></IconButton>
                    </Tooltip>
                </div>}
              </div>)
            })}
            {
              data.op.length === 0 && <div></div>
            }
            </div>)
          }},
          { title: '', field: 'bComplete', render:(data, t) => {
            return (<div style={{textAlign:'right'}}>
              </div>)
          }},
        ]}
        data={sessions.map((v, i) => {
          return {id:v.id, node:v.node!, bComplete:v.bComplete, next:v.next_node, loading:(v.weights + '/' + v.threshold), op:v.holders}
        })}   
        options={{padding: "dense", tableLayout:'fixed',
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],}}
        actions={[]}
        components={{
          Header: (props) => {
            return (<TableHead {...props} >
              <TableRow>
                <TableCell variant='head' align='left' style={{width:'2em', paddingLeft:'4px'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div>{props.columns[0].title}</div>
                      <div><Tooltip title='Session-id, used for parent progress setting.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div> 
                  </div>    
                </TableCell>
                <TableCell variant='head' align='center' style={{width:'9em'}}>
                  <div style={{display:'flex'}}>
                    <div>{props.columns[1].title}</div>                  
                  </div>
                </TableCell>
                <TableCell variant='head' align='center' style={{width:'9em'}}>
                  <div style={{display:'flex'}}>
                    <div>{props.columns[2].title}</div>                  
                  </div>
                </TableCell>
                <TableCell variant='head' align='right' style={{width:'6em'}}>
                  <div> {props.columns[3].title}</div>
                  </TableCell>
                <TableCell variant='head' align='left' width='100%' style={{paddingRight:'2em'}}>{props.columns[4].title}</TableCell>
                <TableCell variant='head' hidden>{props.columns[5].title}</TableCell>
              </TableRow>
            </TableHead>)
          },
            Toolbar: props => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}} >
                  <Typography variant='h6' sx={{ml:'16px', mt:'4px', color:green[600]}} >{bComplete ? 'Progress Completed!' : ''}</Typography>
                    <MTableToolbar {...props} />
                </div>
            ),
        }}
      />    
      <DeliverableShow deliverable={show} onClose={()=>{setShow(undefined)}} /> 
    </Paper>
  );  
}